@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";


.mainButton {
    padding: 5px 30px;
    text-transform: uppercase;
    border: 1px solid $primary-color;
    border-radius: 20px;
    background-color: transparent;
    color: $primary-light;
    font-size: 15px;
    font-weight: 300;
    transition: all 300ms;

    &:not(:disabled):hover{
        cursor: pointer;
        scale: 105%;
        background-color: $primary-color;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    }

    &:disabled {
        cursor: not-allowed;
    }

}