@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.notification {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 100%;

    &.exit {
        animation: SlideRight 0.4s;
        animation-fill-mode: forwards;
    }

    p {
        margin: 0;
        padding: 3px 10px;
    }

    .bar {
        height: 2px;
    }

    &.success {
        background-color: $success-color;
        .bar {
            background-color: $font-color-2;
        }
    }

    &.error {
        background-color: $error-color;
        .bar {
            background-color: $font-color-2;
        }
    }

    .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: $font-color-2;

    }

    .message {
        font-family: 'Poppins', sans-serif;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        font-weight: 200;
        color: $font-color-2;
        font-size: 12px;
    }



}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}



