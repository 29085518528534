@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.about {

    .titleArea {
        min-height: 12vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 25px;
            font-weight: 300;
            line-height: normal;
            font-family: 'Raleway', sans-serif;
        }

        .description {
            margin-top: 5px;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            line-height: normal;
            font-family: 'Raleway', sans-serif;
        }

        .buttons {
            text-align: center;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .contactDetails {
                word-break: break-all;
                display: flex;
                align-items: center;
                padding: 5px;
                font-family: 'Raleway', sans-serif;
                font-size: 0px;
                transition: .5s;

                .content{
                    position: absolute;
                    width: 100%;
                    left:0;
                }
                &:hover {
                    cursor: pointer;
                    font-size: 15px;

                    @include sm {
                        font-size: 10px;
                    }

                }
            }

        }


    }

    .mainSection {
        margin: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px black;
        padding: 50px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        flex-direction: row-reverse;
        @include lg {
            flex-direction: column;
        }

        @include sm {
            margin: 10px;
            padding: 50px 20px;
        }

        .imageBox{
            display: flex;
            justify-content: center;
            max-width: 100%;
            flex:2;
            .image{
                max-width: 100%;
                @include lg{
                    max-width: 30%;
                }
                @include md{
                    max-width: 70%;
                }
            }
        }


        .textArea {  
            background: white;
            padding: 20px;
            flex: 3;
            text-align: justify;
            max-height: 60vh;
            min-height: 400px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track {
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $hover-color;
                border-radius: 10px;
            }
        
            /* Scrollbar itself */
            &::-webkit-scrollbar {
                width: 5px;
                height: 12px; 
            }
    
            @include lg {
                max-height: none;
            }

            .title {
                margin: 10px 0;
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                font-family: 'Raleway', sans-serif;
            }

            .detail {
                margin: 10px 0;
                font-size: 16px;
                font-weight: 300;
                line-height: 1.3;
                font-family: 'Raleway', sans-serif;
            }

            .point {
                margin: 5px 0;
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Raleway', sans-serif;

                .sub-points {
                    margin: 7px 0;

                }

                .main {
                    font-weight: 500;
                }
            }

            .button {
                margin: 20px 0;
                text-align: center;
            }

        }
    }

}