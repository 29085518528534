.loader {

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1500;
    background: rgba(0, 0, 0, 0.7);



    img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1070;
        position: absolute;
    }
}