@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.privacyPolicy {
    .titleArea {
        min-height: 12vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 25px;
            font-weight: 300;
            line-height: normal;
            font-family: 'Raleway', sans-serif;
        }

        .description {
            margin-top: 5px;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            line-height: normal;
            font-family: 'Roboto Flex', sans-serif;
        }
    }

    .mainSection {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px;
        border-radius: 10px;
        padding: 50px 40px;
        flex-wrap: wrap;
        // overflow: hidden;
        flex-direction: row-reverse;
        position: relative;

        @include md {
            display: block;
        }

        .privacy-policy-background{
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            // overflow: hidden;
            .fog-effect {
                position: absolute;
                background: linear-gradient(to bottom, $font-color-2 0%, transparent 40%, transparent 60%, $font-color-2 100%);
                height: 100%;
                width: 100%;
            }
    
    
            .circles {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
    
    
                li {
                    position: absolute;
                    display: block;
                    list-style: none;
                    width: 20px;
                    height: 20px;
                    background: $hover-color;
                    animation: animate 25s linear infinite;
                    transform: translateY(0) rotate(0deg);
                    bottom: -150px;
                    transition: 1s;
    
                    &:nth-child(1) {
                        left: 25%;
                        width: 80px;
                        height: 80px;
                        animation-delay: 0s;
                    }
    
                    &:nth-child(2) {
                        left: 10%;
                        width: 20px;
                        height: 20px;
                        animation-delay: 1s;
                        animation-duration: 6s;
                    }
    
                    &:nth-child(3) {
                        left: 70%;
                        width: 20px;
                        height: 20px;
                        animation-delay: 2s;
                    }
    
                    &:nth-child(4) {
                        left: 40%;
                        width: 60px;
                        height: 60px;
                        animation-delay: 0s;
                        animation-duration: 9s;
                    }
    
                    &:nth-child(5) {
                        left: 65%;
                        width: 20px;
                        height: 20px;
                        animation-delay: 0s;
                    }
    
                    &:nth-child(6) {
                        left: 75%;
                        width: 110px;
                        height: 110px;
                        animation-delay: 2s;
                    }
    
                    &:nth-child(7) {
                        left: 35%;
                        width: 150px;
                        height: 150px;
                        animation-delay: 7s;
                    }
    
                    &:nth-child(8) {
                        left: 50%;
                        width: 25px;
                        height: 25px;
                        animation-delay: 7s;
                        animation-duration: 22s;
                    }
    
                    &:nth-child(9) {
                        left: 20%;
                        width: 15px;
                        height: 15px;
                        animation-delay: 1s;
                        animation-duration: 12s;
                    }
    
                    &:nth-child(10) {
                        left: 85%;
                        width: 150px;
                        height: 150px;
                        animation-delay: 0s;
                        animation-duration: 5s;
                    }
    
                    @keyframes animate {
                        0% {
                            transform: translateY(0) rotate(0deg);
                            opacity: 1;
                            border-radius: 0;
                        }
            
                        100% {
                            transform: translateY(-1000px) rotate(720deg);
                            opacity: 0;
                            border-radius: 50%;
                        }
            
                    }
                }
            }
         }

        .textArea {
            padding: 12px;
            max-width: 1000px;
            padding: 50px 40px;
            border-radius: 10px;
            box-shadow: 0px 0px 3px ;
            background-color: $white-transparent50;
            backdrop-filter: blur(10px);

            .title {
                margin: 10px 0;
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                font-family: 'Raleway', sans-serif;
            }

            .detail {
                margin: 10px 0;
                font-size: 15px;
                font-weight: 300;
                line-height: 1.3;
                font-family: 'Raleway', sans-serif;
            }

            .point {
                margin: 5px 0;
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                font-family: 'Raleway', sans-serif;

                .main {
                    font-weight: 500;
                }
            }

            .button {
                margin: 35px 0;
                text-align: center;
            }

            .navLink {
                font-size: 14px;
                font-weight: 500;
                font-family: 'Roboto Flex', sans-serif;
                transition: all 0.3s;
                color: $button-color;

                &:hover {
                    color: $hover-color;

                }
            }

        }
    }

}