@import "../../../styles/responsive.scss";
@import "../../../styles/variables.scss";

.MainSwiper {
    overflow: hidden;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
    padding: 10px;
    position: relative;

    @include md {
        height: 70vh;
        min-height: 600px;
    }

    .rotation {
        position: absolute;
        height: 600px;
        transition: 1s;

        @include md {
            height: 500px;
        }

        @include sm {
            height: 250px;
        }

    }

    .slideBackGround {
        position: absolute;
        height: 800px;
        width: 100%;
        overflow: hidden;
        display: float;

        @include md {
            height: 400px;
        }


        .fog-effect {
            position: absolute;
            background: linear-gradient(to bottom, $font-color-2 0%, transparent 40%, transparent 60%, $font-color-2 100%);
            height: 100%;
            width: 100%;
        }


        .circles {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;


            li {
                position: absolute;
                display: block;
                list-style: none;
                width: 20px;
                height: 20px;
                animation: animate 25s linear infinite;
                transform: translateY(0) rotate(0deg);
                bottom: -150px;
                transition: 1s;

                &:nth-child(1) {
                    left: 25%;
                    width: 80px;
                    height: 80px;
                    animation-delay: 0s;
                }

                &:nth-child(2) {
                    left: 10%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 2s;
                    animation-duration: 12s;
                }

                &:nth-child(3) {
                    left: 70%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 4s;
                }

                &:nth-child(4) {
                    left: 40%;
                    width: 60px;
                    height: 60px;
                    animation-delay: 0s;
                    animation-duration: 18s;
                }

                &:nth-child(5) {
                    left: 65%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 0s;
                }

                &:nth-child(6) {
                    left: 75%;
                    width: 110px;
                    height: 110px;
                    animation-delay: 3s;
                }

                &:nth-child(7) {
                    left: 35%;
                    width: 150px;
                    height: 150px;
                    animation-delay: 7s;
                }

                &:nth-child(8) {
                    left: 50%;
                    width: 25px;
                    height: 25px;
                    animation-delay: 15s;
                    animation-duration: 45s;
                }

                &:nth-child(9) {
                    left: 20%;
                    width: 15px;
                    height: 15px;
                    animation-delay: 2s;
                    animation-duration: 35s;
                }

                &:nth-child(10) {
                    left: 85%;
                    width: 150px;
                    height: 150px;
                    animation-delay: 0s;
                    animation-duration: 11s;
                }

                @keyframes animate {
                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border-radius: 0;
                    }
        
                    100% {
                        transform: translateY(-1000px) rotate(720deg);
                        opacity: 0;
                        border-radius: 50%;
                    }
        
                }
            }
        }


    }


    .backSwiper {
        position: absolute;
        width: 30%;
        transition: 1s;
        left: 0px;
        height: 100x;


        .imageSwiper {


            .slide {

            }

        }
    }

    .swiper {
        .slide {
            height: 800px;
            display: flex;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            background-color: rgba(100, 100, 100, 0);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            .box {
                height: 100%;
                box-shadow: 0px 0px 3px black;
                border-radius: 20px;
                display: flex;
                gap: 50px;
                align-items: center;
                background: $white-transparent50;
                backdrop-filter:blur(10px);
                padding: 2vw;
                min-height: 500px;
                max-height: 50vh;
                max-width: 60vw;

                @include xl {
                    max-width: 80vw;
                }

                @include md {
                    max-width: 90vw;
                    min-height: 400px;
                }

                @include sm {
                    flex-direction: column;
                    padding-top:20px;
                    gap: 10px;
                }

                .projectImage {
                    width: 40%;
                    height: 300px;
                    object-fit: contain;
                    margin-top: 0px;

                    @include lg {
                        height: 200px;
                    }

                    @include sm {
                        height: 120px;
                    }

                }

                .description {
                    margin: 10px;
                    display: flex;
                    flex-direction: column;
                    max-width: 400px;
                    gap: 20px;
                    overflow: hidden;

                    @include md {
                        gap: 10px;
                    }

                    .slideTitle {
                        .preTitle {
                            font-size: 13px;
                            font-weight: 500;

                            &:hover {
                                cursor: pointer;
                                color: $hover-color;
                            }
                        }

                        .heading {
                            font-size: 25px;
                            font-weight: 200;
                        }
                    }

                    .text {
                        font-size: 15px;
                        font-weight: 300;
                        word-break: break-all;
                        max-height: 30vh;
                        min-height:  200px;
                        overflow-y: scroll;
                        @include md {
                            min-height: 100px;
                        }

                        &::-webkit-scrollbar {
                            width: 0px;
                        }

                    }

                    .link {
                        cursor: pointer;
                        transition: .1;

                        &:hover {
                            color: $hover-color;
                            transform: scale(1.01);
                        }
                    }
                }
            }
        }
    }

    .swiper-pagination-bullet {
        min-width: 60px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #000;
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
    }

    .swiper-pagination-bullet-active {
        color: #fff;
        background: #6a00ff;
    }

    .arrow-buttons {
        position: absolute;
        z-index: 1;
        bottom: 2%;
        gap: 10px;

        .arrow {
            flex: 1;
            width: 100px;
            background-color: $font-color-2;
            padding: 10px;
            border: none;
            transition: .1s;
            cursor: pointer;

            &:hover {
                background-color: $hover-color;
                color: $font-color-2;
            }

        }

        .arrow-left {
            border-radius: 250px 0px 0px 250px;
        }

        .arrow-right {
            border-radius: 0px 250px 250px 0px;
        }

        .swiper-button-disabled {
            cursor: default;
            color: $footer-item-color;

            &:hover {
                background-color: white;
                color: $footer-item-color;
            }
        }
    }
}