
$primary-color: #0A3140;
$primary-light: #69828C;

$font-color-1:black;
$font-color-2:white;
$description-box-background:rgba(255, 255, 255, 0.233);
$primary-background: #0f3d40;

$white-transparent50 : rgba(255, 255, 255, 0.500);

$accent-color: #B6F2EC;
$accent-light: #4D6973;
$text-color: #8FA1A6;
$dark-text: #0D0D0D;
$secondary-background-color:#342253;
$hover-color :#8e44ad;
$badge-text-color : #e3a3eb;
$badge-border-color :#ad1cbd;
$success-color :rgba(50,1,103,0.8576024159663865);
$error-color : #c23616;
$footer-item-color : #a4b0be;
$button-color : #474787;

$margin-color: rgba(13, 13, 13, 0.1);

$grey-color: grey;
$black-color: black;


