@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.header{
    position: relative;
    background-color: transparent;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .left{
        display: flex;
        align-items: center;

        .logo{
            max-height: 120px;
            width: auto;
            transition: all 0.3s;
            padding-left: 20px;

            @include sm {
                max-height: 50px;
            }

            &:hover{
                cursor: pointer;
                transform: scale(1.05);
            }
        }
    }
    .right{
        display: flex;
        align-items: center;

        .navigate{
            display:flex;
            align-items:center;

            .navigationLink{
                font-size: 15px;
                font-weight: 300;
                color: linear-gradient(0deg, rgba(14,11,17,0) 24%, rgba(50,1,103,1) 51%, rgba(81,18,150,0) 80%);
                font-family: 'Raleway', sans-serif;
                transition: all 0.3s;
                padding: 0px 20px;
                border-right: 1px solid black;

                &:last-child{
                    border-right: none;
                }

                &:hover{
                    color: $hover-color;
                    transform: scale(1.05);
                    cursor: pointer;
                }
            }
        }
    }
}


.drawer {
    width: 250px;
    background-color: $font-color-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .navButtons {
        margin: 20px;

        .navLink {
            margin: 20px;
            text-align: center;
            display: block;
            font-size: 15px;
            font-weight: 500;
            font-family: 'Raleway', sans-serif;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                color: $primary-color;
                transform: scale(1.05);
            }

        }
    }

}