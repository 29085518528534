@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.mainDropdown {
    .title {
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        font-family: 'Raleway', sans-serif;
        margin: 5px 0;
    }

    .dropdown {
        margin: 10px 0 5px;
        &.select--is-disabled{
            cursor: not-allowed;
        }
        .select__control {
            border: 1px solid $primary-light;
            border-radius: 5px;
            transition: all 0.3s;

            &--is-focused {
                border-color: $primary-color;
                box-shadow: 0px 0px 3px 1px $primary-color;
            }

            &--is-disabled{
                background-color: $primary-background;
                cursor: not-allowed;
                pointer-events: all;
            }

            .select__value-container {

                .select__single-value{

                    &--is-disabled {
                            color: $dark-text;
                        }
                }
                .select__placeholder {
                    font-size: 14px;
                    font-family: 'Raleway', sans-serif;
                }

                .select__input-container {
                    .select__input {
                        font-size: 15px;
                        font-family: 'Raleway', sans-serif;
                    }
                }

                padding: 0px 5px;
            }
        }

        .select__menu {
            z-index: 100;

            .select__menu-list {
                &::-webkit-scrollbar {
                    display: none;
                }

                .select__option {
                    font-size: 15px;
                    font-family: 'Raleway', sans-serif;

                    &--is-focused {
                        background-color: $accent-color;
                    }

                    &--is-selected {
                        background-color: $primary-color;
                    }
                }
            }
        }

    }

}