@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.footer{
   padding: 80px 7vw 20px;
   background:$dark-text;
   position: relative;
   @include sm {
       padding: 15px 7vw;
   }
   h1{
       font-size: 35px;
       text-align: center;
       margin-top: 50px;
       justify-content: center;
       color:$font-color-2;
   }
   .logoArea {
    display: flex;
    justify-content: space-between;
        .logo {
            height: 60px;
            
            @include md {
                    height: 50px;
                }

            @include sm {
                    height: 30px;
                }
        }
        .badge{
            height: 80px;
            @include md {
                    height: 50px;
                }

            @include sm {
                    height: 30px;
                }
        }
}
   .sectionsArea {
       margin: 30px;
       padding: 10px 0px;
       display: flex;
       flex-wrap: wrap;
       justify-content: space-evenly;
       gap: 10px;
       overflow: hidden;

       .section {

           .title {
               font-size: 15px;
               margin: 20px 0px;
               font-weight: 400;
               font-family: 'Raleway', sans-serif;
               color: $font-color-2;
           }

           .navLink {
               display: block;
               margin: 10px 0px;
               font-size: 14px;
               font-weight: 300;
               font-family: 'Raleway', sans-serif;
               transition: all 0.3s;
               color: $footer-item-color;
               transform-origin: 0% 50%;

               &:hover {
                   color: $hover-color;
                   transform: scale(1.1);

               }
           }

       }
   }

   .bottomArea {
       border-top: 1px solid $margin-color;

       .copyRightText {
           padding: 20px 0;
           font-size: 14px;
           font-weight: 300;
           font-family: 'Raleway', sans-serif;
           color: $primary-background;

           .link {
               color: $primary-color;
               transition: all 0.3s;

               &:hover {
                   cursor: pointer;
                   font-weight: 400;
               }
           }
       }
   }

}
