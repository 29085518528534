@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.go-to-top-button{
    position: fixed;
    bottom: 10%;
    right: 5%;
    background-color: $white-transparent50;
    border-radius: 50%;
    aspect-ratio : 1 / 1;
    backdrop-filter:blur(1px);
    z-index: 100;
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 5px $grey-color;
    &:hover{
        box-shadow: 0px 0px 10px $black-color;
        backdrop-filter:blur(10px);
        scale:1.05;
    }
}
.home {
    max-width: 100%;
    transition: 1s;
    overflow: hidden;
    
    .mainSection {
        padding: 5vh;
        text-align: center;
        .logoGif{
            width: 50vw;
            margin: -100px 0px -100px;
            @include sm{
                width: 80vw;
            }
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70vh;
            min-height: 500px;
            @include sm{
                height: 50vh;
            }
            
            .text {
                font-size: 100px;
                font-weight: 200;
                text-shadow: 4px 5px 20px grey;
                color: transparent;
                background: linear-gradient(to bottom right, rgba(14,3,38,1) 35%, rgba(50,1,103,0.8576024159663865) 66%);
                background-clip: text;
                -webkit-background-clip: text;
                background-size: 200% 200%;
                animation: textBackgroundMove 5s ease-in-out infinite,textLoad 4s ease-in-out;
                @include sm{
                    font-size:50px;
                }
                @keyframes textLoad {
                    0%{
                        color: black;
                    }
                    50%{
                        color: black;
                    }
                    100%{
                        color: transparent;
                    }
                }
                @keyframes textBackgroundMove {
                    0%{
                        background-position: 0% 0%;
                    }
                    20%{
                        background-position: 100% 100%;
                    }
                    50%{
                        background-position: 0% 0%;
                    }
                    80%{
                        background-position: 100% 100%;
                    }
                    100%{
                        background-position: 0% 0%;
                    }

                }
            }
        }

        .description {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70vh;
            background:linear-gradient(to bottom right , black 40%,gray 50%, black 50%);
            background-size: 200% 200%;
            background-clip: text;
            -webkit-background-clip: text;
            animation: backgroundMove 5s ease-in-out infinite;
            min-height: 500px;
            @include sm{
                height: 50vh;
                padding:1vh;
                // min-height: 800px;
            }
            @keyframes backgroundMove {
                0%{
                    background-position: 100% 100%;
                }
                50%{
                    background-position: 100% 100%;
                    transform:scale(1)
                }
                80%{
                    background-position: 0% 0%;
                    transform: scale(1.01);
                }
                100%{
                    background-position: 0% 0%;
                    transform: scale(1);
                }

            }
            .text {
                text-align: center;
                font-size: 30px;
                padding: 2vh;
                color:transparent;
                -webkit-text-fill-color : transparent;
                font-style: italic;
                font-weight: 100;
                @include md {
                    font-size: 20px;
                }
                @include sm{
                    text-align: justify;
                    font-size: 15px;
                    padding: 1vh;
                }
            }
        }
    }

   
    .project-section {
        min-height: 90vh;
        min-height: 750px;
        position: relative;
        background-size: auto 80%;
        background-position: 0% 100%;
        background-repeat: no-repeat;
        transition: .2s ease-in-out;
        overflow: hidden;
        @include xl{
            min-height: 850px;
        }
        @include lg{
            min-height: 720px;
        }


        .project-section-heading {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100vw;
            flex-direction: column;

            .project-section-heading-text{
                min-width: 200px;
                color:transparent;
                font-weight: 400;
                margin: 0px 50px;
                font-size: 50px;
                background:linear-gradient(to bottom right , rgba(14,3,38,1) 35%, rgba(50,1,103,0.8576024159663865) 50%, rgba(14,3,38,1) 60%);
                background-size: 200% 200%;
                background-clip: text;
                -webkit-background-clip: text;
                animation: backgroundMove 8s ease-in-out infinite;
                @include md{
                    // margin:-50px 20px;
                }
                @include sm {
                    font-size:35px;
                }    
    
                @keyframes backgroundMove {
                    0%{
                        background-position: 100% 100%;
                    }
                    50%{
                        background-position: 100% 100%;
                        transform:scale(1)
                    }
                    80%{
                        background-position: 0% 0%;
                        transform: scale(1.01);
                    }
                    100%{
                        background-position: 0% 0%;
                        transform: scale(1);
                    }
    
                }
            }
    
            .project-box-selector{
                min-width: 200px;
                display: flex;
                justify-content: flex-end;
                text-shadow: 1 1 1px black;
                margin:20px;
                // padding-top: 2%;
                .items{
                    background:white;
                    border-radius: 100px;
                    overflow: hidden;
                    display: flex;
                    box-shadow: .1px .1px 10px rgba(0, 0, 0, 0.195);
                    .item{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin:auto;
                        min-width: 100px;
                        min-height: 50px;
                        border-radius:0%;
                        @include sm{
                            min-width: 50px;
                            min-height: 25px;
                        }
                        &:hover{
                            color:$font-color-2;
                            background-color: gray;
                        }
                    }
                    .selected-item{
                        backdrop-filter: blur(200px);
                        background-color: $hover-color;
                        color: $font-color-2;
                    }
                }
            }
        }

        .outside-shape{
            float:left;
            height: 80vh;
            width: 100%;
            z-index: 10;
            position: absolute;
            clip-path: polygon( 0% 0%, 20% 0%, 40% 100%, 0% 100% );
            shape-outside:polygon( 0% 0%, 20% 0%, 40% 100%, 0% 100% );


        }
        .project{
            overflow: hidden;
            width: 100%;
            height: 80vh;
            min-height: 95%;
            position: absolute;
            right:-100%;
            transition: .8s ease-in-out;
            overflow: hidden;
            .project-title{
                position: absolute;
                color:white;
                left:17%;
                top:7%;
                font-size: 50px;
                z-index: 1;
                letter-spacing: 5px;
                font-weight: 800;
                text-shadow: 1px 1px 2px darkblue, 0 0 1em grey, 0 0 0.2em black;
                @include md {
                    font-size:35px;
                    top:2%;
                }
                @include sm {
                    font-size: 22px;
                    top:5%;
                }
            }
            .project-box-container {
                position: absolute;
                bottom: 0px;
                height: 80vh;
                min-height: 100%;
                width: 100%;
                left: 20%;
                backdrop-filter: blur(20px);
                -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%);
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%);
                display: flex;
                flex-direction: column;

                .project-box-heading{
                    flex:3;
                    position: relative;
                    background-color: red;
                    background-clip: text;
                    z-index: 100;
                    color:transparent;
                    @include md {
                        flex:2.3;
                    }
                }
                .project-box-description{
                    position: relative;
                    flex:9;
                    max-width: 70px;
                    .text-body{
                        position: absolute;
                        .text-wrap-shape-left {
                            float: left;
                            shape-outside: polygon(0% 0%, 10% 0%, 40% 100%, 0% 100%);
                            clip-path: polygon(0% 0%, 10% 0%, 40% 100%, 0% 100%);
                            width: 75vw; 
                            height: 90vh;
                            min-height: 900px; 
                            margin-right: 20px;
                            @include sm{
                                margin-right: 10px;
                            }
                        }
                        .text{
                            font-size:20px;
                            text-align: justify;
                            line-height: 150%;
                            color: white;
                            text-shadow: 1 1 1px black;
                            @include xl{
                                font-size: 20px;
                            }
                            @include lg{
                                font-size: 17px;
                            }
                            @include md {
                                font-size: 15px;
                            }
                            @include sm {
                                font-size: 13px;
                                margin: 1px 5px;
                            }
                        }
                        .icons{
                            display:flex;
                            position: absolute;
                            left:20%;
                            margin:10% 5%;
                            gap:20%;
                            .badge-icon{
                                color:white;
                                font-size: 50px;
                                @include lg{
                                    font-size: 40px;
                                }
                            }
                        }
                    }
                    
                }
            }
        }
        

    }

    .swiper-section{
        margin-top: 250px;
        .swiper-heading{
            color:transparent;
            position: absolute;
            font-weight: 400;
            // text-shadow: 1px 1px 20px $text-color;
            margin:10px 50px;
            font-size: 50px;
            background:linear-gradient(to bottom right , rgba(14,3,38,1) 35%, rgba(50,1,103,0.8576024159663865) 50%, rgba(14,3,38,1) 60%);
            background-size: 200% 200%;
            background-clip: text;
            -webkit-background-clip: text;
            animation: backgroundMove 8s ease-in-out infinite;
            z-index: 1;
            @include sm {
                font-size:35px;
                margin:-10px 20px;
            }
    
            @keyframes backgroundMove {
                0%{
                    background-position: 100% 100%;
                }
                50%{
                    background-position: 100% 100%;
                    transform:scale(1)
                }
                80%{
                    background-position: 0% 0%;
                    transform: scale(1.01);
                }
                100%{
                    background-position: 0% 0%;
                    transform: scale(1);
                }
    
            }
        }
    }
    
}